




  import { Component, Vue, Watch, namespace } from 'nuxt-property-decorator'
  import { CMS } from '@one/types'
  import qs from 'qs'

  const categories = namespace('categories')

  type ListingQueries = string | string[]

  @Component
  export default class OneListingBanner extends Vue {
    name = 'OneListingBanner'

    @categories.Getter getSelectedCategory: any

    bannerMatchingCurrentPath: CMS.ListingBanner | null = null
    bannersList = [] as CMS.ListingBannerList

    setProductsLayout(component: string) {
      this.$emit('layout-change', component)
    }

    async fetchMatchingBanner(id: string) {
      if (!id) { return }
      try {
        const { data: banner } = await this.$api.cms.app.getBanner(id)
        this.bannerMatchingCurrentPath = banner
      } catch (error) {
        this.$logger.error(error)
      }
    }

    compareBannerUrlWithPath() {
      const mostAccurateBanner: { id: string, matchedFiltersAmount: number } = {
        id: '',
        matchedFiltersAmount: 0,
      }
      this.bannersList.forEach((banner: CMS.ListingBannerListItem) => {
        const [bannerUrl, bannerQuery] = banner.url.split('?')
        const urlFilters = this.$route.query.filtersQuery as ListingQueries
        const bannerFilters = qs.parse(bannerQuery).filtersQuery as ListingQueries

        const categoriesMatch = () => {
          const currentCategory = this.getSelectedCategory
            ? this.getSelectedCategory.url
            : this.$route.path
          const bannerCategoryPath = new URL(bannerUrl).pathname
          return bannerCategoryPath.endsWith(currentCategory)
        }
        const urlIncludesAllBannerFilters = (): boolean => {
          if (!bannerFilters && !urlFilters) {
            if (mostAccurateBanner.matchedFiltersAmount === 0) {
              mostAccurateBanner.id = banner.id
            }
            return true
          }
          const ensureArray = (raw: ListingQueries): string[] => Array.isArray(raw) ? raw : [raw]

          const comparableBannerFilters = ensureArray(bannerFilters)
          const comparableUrlFilters = ensureArray(urlFilters)

          if (comparableBannerFilters.length > comparableUrlFilters.length) {
            return false
          } else {
            const filterMatches = comparableUrlFilters.filter(f => comparableBannerFilters.includes(f)).length
            if (
              filterMatches === comparableBannerFilters.length &&
              mostAccurateBanner.matchedFiltersAmount <= filterMatches
            ) {
              mostAccurateBanner.id = banner.id
              mostAccurateBanner.matchedFiltersAmount = filterMatches
              return true
            }
            return false
          }
        }

        if (categoriesMatch() && urlIncludesAllBannerFilters()) {
          this.fetchMatchingBanner(mostAccurateBanner.id)
        } else {
          this.bannerMatchingCurrentPath = null
        }
      })
    }

    @Watch('$route.fullPath')
    function() {
      this.compareBannerUrlWithPath()
    }

    async mounted() {
      try {
        const { data: list } = await this.$api.cms.app.getBannersList()
        this.bannersList = list
      } catch (error) {
        this.$logger.error(error)
      } finally {
        if (this.bannersList.length) {
          this.compareBannerUrlWithPath()
        }
      }
    }
  }
